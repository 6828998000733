import React, { useRef, useState } from 'react';
import { Formik, Form, Field, CccisdInput, CccisdTextarea, CccisdDatepicker } from 'cccisd-formik';
import { FieldBlock } from 'cccisd-redux-form-helpers';
import classnames from 'classnames';
import axios from 'cccisd-axios';
import moment from 'moment';
import notification from 'cccisd-notification';
import { ResourceCenterWithCollection } from 'cccisd-laravel-resources/dist/Collection';
import AssistLogo from '../../../images/Assist_MH_Logo.svg';
import PlusCircleIcon from 'cccisd-icons/plus-circle';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const CreateTreatmentPlan = props => {
    const [currentPage, setCurrentPage] = useState(1);
    const [treatmentPlanName, setTreatmentPlanName] = useState('');

    const modal = useRef();
    let config = props.config;

    const timelineOptions = [
        { label: 'Weekly', value: 'weekly' },
        { label: 'Monthly', value: 'monthly' },
        { label: '90 days', value: '90Days' },
        { label: 'Custom', value: 'custom' },
    ];

    const applyTimezoneOffset = date => {
        // Our server uses UTC time so we need to offset the start/end date entered by client
        return moment(date).utc().format('MM-DD-YYYY');
    };

    const closeModal = () => {
        modal.current.close();
    };

    // adding a new plan for the user
    const onSubmit = async vals => {
        vals.sessions[0].sessionType = 'offset';
        vals.sessions[0].offset = 0;
        vals.sessions[0].opensAt = null;
        vals.sessions[0].closesAt = null;

        vals.sessions.forEach((session, index) => {
            if (vals.sessions[index].settings.reporter === 'Provider-led') {
                vals.sessions[index].settings.handle = 'provider';
                vals.sessions[index].deploymentId = 1;
            }
            if (vals.sessions[index].settings.reporter === 'Student-led') {
                vals.sessions[index].settings.handle = 'student';
                vals.sessions[index].deploymentId = 2;
            }
        });

        let planData = {
            creatingPawnId: Fortress.user.acting.id,
            label: treatmentPlanName,
            description: vals.planDescription,
            pawnIds: vals.respondents.map(r => r.id),
            sessions: vals.sessions,
        };

        await axios.post(Boilerplate.route('api.assignment.plan.create'), planData).then(response => {
            if (response.data.status === 'success') {
                notification({
                    message: 'Strategy(ies) have been added to your treatment plan.',
                    type: 'success',
                    duration: 8000,
                });
                window.location.href = Boilerplate.url('/students');
            } else {
                notification({
                    message: 'Error adding strategy(ies) to your treatment plan.',
                    data: response && response.data && response.data.errors,
                    duration: -1,
                    type: 'danger',
                });
            }
        });
    };

    //  updating existing plan
    // const onSubmitEdit = async vals => {
    //     vals.sessions[0].sessionType = 'offset';
    //     vals.sessions[0].offset = 0;
    //     vals.sessions[0].opensAt = null;
    //     vals.sessions[0].closesAt = null;

    //     if (vals.sessions[0].settings.reporter === 'Provider') {
    //         vals.sessions[0].settings.handle = 'provider';
    //     }
    //     if (vals.sessions[0].settings.reporter === 'Student') {
    //         vals.sessions[0].settings.handle = 'student';
    //     }

    //     let planData = {
    //         creatingPawnId: Fortress.user.acting.id,
    //         label: treatmentPlanName,
    //         pawnIds: vals.respondents.map(r => r.id),
    //         sessions: vals.sessions,
    //     };
    //     // console.log({ planData });
    //     // console.log(vals.planId);

    //     await axios
    //         .put(
    //             Boilerplate.route('api.assignment.plan.update', {
    //                 plan: vals.planId,
    //             }),
    //             planData
    //         )
    //         .then(response => {
    //             if (response.data.status === 'success') {
    //                 notification({
    //                     message: 'Stragy(ies) have been updated.',
    //                     type: 'success',
    //                     duration: 8000,
    //                 });
    //                 window.location.href = Boilerplate.url('/myCourses');
    //             } else {
    //                 notification({
    //                     message: 'Error updating your strategy(ies).',
    //                     data: response && response.data && response.data.errors,
    //                     duration: -1,
    //                     type: 'danger',
    //                 });
    //             }
    //         });
    // };

    const validate = values => {
        try {
            let errors = {};
            if (!values.planName) {
                errors.planName = 'Treatment Plan Name is required';
            } else {
                setTreatmentPlanName(values.planName);
            }

            if (!values.timelineStart) {
                errors.timelineStart = 'Timeline is required.';
            }

            return errors;
        } catch (e) {
            console.error(e);
        }
    };

    const getInitialValues = () => {
        return {};
    };

    const renderResourceTiles = (data, renderDefault) => {
        return <div className={style.resourceTiles}>{renderDefault(data)}</div>;
    };

    return (
        <div>
            <Formik
                onSubmit={onSubmit}
                validate={validate}
                initialValues={getInitialValues()}
                render={formProps => {
                    return (
                        <Form>
                            <div className={currentPage === 1 ? style.show : style.hidden}>
                                <div className={style.basicFields}>
                                    <div className={style.inputFields}>
                                        <div>
                                            <label className={`form-label ${style.required}`}>
                                                Treatment Plan Name:
                                            </label>
                                            <Field name="planName" component={CccisdInput} />
                                            {formProps.errors.planName && formProps.touched.planName && (
                                                <div className={style.error}>{formProps.errors.planName}</div>
                                            )}
                                        </div>
                                        <div>
                                            <label className="form-label">Symptoms Summary/Notes:</label>
                                            <Field name="planDescription" component={CccisdTextarea} />
                                        </div>
                                        <div>
                                            <label className="form-label">Treatment Goal 1:</label>
                                            <Field name="goal1" component={CccisdInput} />
                                        </div>
                                        <div>
                                            <label className="form-label">Treatment Goal 2:</label>
                                            <Field name="goal2" component={CccisdInput} />
                                        </div>
                                        <button type="button" className={`btn btn-primary ${style.addGoal}`}>
                                            Add Goal
                                            <PlusCircleIcon spaceLeft />
                                        </button>
                                    </div>
                                    <div className={style.timelineStart}>
                                        <div>
                                            <label className={`form-label ${style.required}`}>
                                                Treatment Plan Timeline:
                                            </label>
                                            <Field
                                                name="timelineStart"
                                                component={CccisdDatepicker}
                                                showYearDropdown
                                                placeholderText="Select start date"
                                            />
                                            {formProps.errors.timelineStart && formProps.touched.timelineStart && (
                                                <div className={style.error}>{formProps.errors.timelineStart}</div>
                                            )}
                                        </div>
                                    </div>

                                    {/* <ReviewTimeline name="oldtimeline" label="I would like to review these goals:" /> */}
                                    <FieldBlock field="timeline" label="I would like to review these goals:">
                                        <div className={classnames(style.flexIt, style.indent)}>
                                            {timelineOptions.map(item => (
                                                <div key={item.value} style={{ margin: 0 }}>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value={item.value}
                                                            checked={formProps.values.timeline === item.value}
                                                            onChange={() =>
                                                                formProps.setFieldValue('timeline', item.value)
                                                            }
                                                        />{' '}
                                                        {item.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>

                                        {formProps.values.timeline === 'custom' && (
                                            <div className={classnames(style.indent, style.marginBottom)}>
                                                <FieldBlock
                                                    field="timelineCustom"
                                                    label="X times in x reporting period"
                                                >
                                                    <input
                                                        min="1"
                                                        className="form-control"
                                                        type="number"
                                                        style={{ maxWidth: '200px' }}
                                                        // {...domOnlyProps(timelineCustom)}
                                                    />
                                                </FieldBlock>
                                            </div>
                                        )}
                                    </FieldBlock>
                                </div>
                            </div>
                            <div className={currentPage === 2 ? style.show : style.hidden}>
                                <a style={{ display: 'flex', justifyContent: 'end' }} href="/">
                                    Skip for now
                                </a>
                                <div style={{ fontWeight: '500' }}>
                                    <strong>Treatment Plan: </strong>
                                    {formProps.values.planName}
                                </div>
                                <div style={{ fontWeight: '500' }}>
                                    <strong>Plan Date: </strong> {applyTimezoneOffset(formProps.values.timelineStart)}
                                </div>
                                <div style={{ fontWeight: '500' }}>
                                    <strong>Student Name: </strong>Test Student 1
                                </div>
                                <div style={{ fontWeight: '500' }}>
                                    <strong>Student ID: </strong>1234
                                </div>

                                <ResourceCenterWithCollection
                                    collectionUserList={[{ id: Fortress.user.acting.id }]}
                                    handle="TreatmentLibrary"
                                    onSubmit={onSubmit}
                                    collectionName="Treatment Plan"
                                    logo={AssistLogo}
                                    renderResourceTiles={renderResourceTiles}
                                    collectionBuckets={props.collectionBuckets || ['Student-led', 'Provider-led']}
                                    planName={formProps.values.planName}
                                    collectionBucketsFormTitle="Treatment Strategies"
                                    disableTimepoints
                                    styleConfig={config.displayOptions}
                                    assessmentLibraryLabel="Treatment Library"
                                    baselineSessionLabel={props.baselineSessionLabel}
                                />
                            </div>
                            <div className={style.navButtons}>
                                <button
                                    className="btn btn-secondary"
                                    type="button"
                                    onClick={() => {
                                        if (currentPage === 1) {
                                            closeModal();
                                        } else {
                                            setCurrentPage(1);
                                        }
                                    }}
                                >
                                    Back
                                </button>
                                <button
                                    className={
                                        currentPage === 1 ? 'btn btn-primary' : `${style.hidden} btn btn-primary`
                                    }
                                    type="button"
                                    onClick={() => {
                                        const values = formProps.values;

                                        formProps.setFieldTouched('planName', true);
                                        formProps.setFieldTouched('timelineStart', true);

                                        if (values.planName && values.timelineStart) {
                                            setCurrentPage(2);
                                        } else {
                                            notification({
                                                message: `Missing Required Fields`,
                                                type: 'danger',
                                            });
                                        }
                                    }}
                                >
                                    Next
                                </button>
                            </div>
                            {/* <button
                                className={currentPage === 2 ? 'btn btn-primary' : `${style.hidden} btn btn-primary`}
                                type="submit"
                            >
                                Submit
                            </button> */}
                        </Form>
                    );
                }}
            />
        </div>
    );
};

export default CreateTreatmentPlan;
