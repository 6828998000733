import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { client } from 'cccisd-apollo';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import { Html } from 'cccisd-wysiwyg';
import Loader from 'cccisd-loader';
import moment from 'moment';
import Modal from 'cccisd-modal';
import style from './style.css';
import _get from 'lodash/get';
import Play from 'cccisd-icons/play4';
import IconCheck from 'cccisd-icons/checkmark-circle';
import resourcesQuery from './getResources.graphql';
// import assignmentPlansQuery from './assignmentPlans.graphql';

const Fortress = window.cccisd.fortress;

const StudentTreatmentStrategies = () => {
    const [loading, setLoading] = useState(true);
    const [studentCollection, setStudentCollection] = useState([]);
    const [flowProgress, setFlowProgress] = useState([]);
    // const [planResources, setPlanResources] = useState([]);

    useEffect(() => {
        getCollections();
    }, []);

    const getCollections = async () => {
        setLoading(true);
        const response = await client.query({
            query: resourcesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: Fortress.user.acting.id,
            },
        });

        const studentResources = _get(response, 'data.resources.resourceList');
        const progressData = _get(response, 'data.flows.flowProgressList');

        setFlowProgress(progressData);
        setStudentCollection(studentResources);

        setLoading(false);
    };

    const applyTimezoneOffset = date => {
        // Our server uses UTC time so we need to offset the start/end date entered by client
        return moment(date)
            .utc()
            .format('MM-DD-YYYY');
    };

    const renderThumbnail = resource => {
        return (
            <div
                className={style.thumbImage}
                style={{
                    backgroundImage: `url(${resource.thumbnail})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                }}
            >
                {/* <img
                    src={resource.thumbnail}
                    alt="resource cover"
                /> */}
            </div>
        );
    };

    const renderSurveyInfo = resource => {
        const metadata = JSON.parse(resource.metadata) || {};

        const btnDataArray = {
            'In Progress': {
                class: 'btn-success',
                icon: Play,
                label: 'Continue',
            },
            start: {
                class: 'btn-primary',
                icon: Play,
                label: 'Start',
            },
            Complete: {
                class: 'btn-primary',
                icon: Play,
                label: 'Retake',
            },
        };

        let status = flowProgress.find(flow => {
            return flow.flowHandle === metadata.surveyInfo.surveyHandle;
        });

        const btnData = btnDataArray[status?.status || 'start'];
        const ButtonIcon = btnData.icon;

        return (
            <div>
                <div>
                    <Modal
                        trigger={
                            <button type="button" className={classnames('btn', btnData.class, style.surveyButton)}>
                                <ButtonIcon /> {btnData.label}
                            </button>
                        }
                        // title={metadata.surveyInfo.surveyLabel}
                        size="large"
                        contentStyle={{ width: '1200px' }}
                        headerStyle={{
                            backgroundColor: '#d68f28',
                            color: '#fff',
                        }}
                        bodyStyle={{ backgroundColor: '#edf2f7' }}
                        afterClose={getCollections}
                    >
                        <div>
                            <DeploymentPlayer
                                deploymentHandle={metadata.surveyInfo.assignment.label}
                                pawnId={Fortress.user.acting.id}
                                pawnHash={Fortress.user.acting.random_hash}
                                disableLayout
                                assignmentOptions={[metadata.surveyInfo.surveyHandle]}
                            />
                        </div>
                    </Modal>
                </div>
                {status?.status === 'Complete' && (
                    <div className={style.surveyStatus}>
                        <span style={{ display: 'flex', alignItems: 'center', color: '#00D067' }}>
                            <span style={{ color: '#283038', marginRight: '65px' }}>
                                Completed on: {applyTimezoneOffset(status.completedDate)}
                            </span>
                            <IconCheck />
                        </span>
                    </div>
                )}
            </div>
        );
    };

    const renderResourceCenter = () => {
        return (
            <div className={style.wrapper}>
                {studentCollection.map((strategy, i) => {
                    return (
                        <div className={style.item} key={strategy.resourceId}>
                            <div className={style.cardWrapper}>
                                {renderThumbnail(strategy)}
                                <div className={style.content}>
                                    <div>
                                        <h3 className={style.title}>{strategy.label}</h3>
                                        <div className={style.description}>
                                            <span style={{ color: '#16498F' }}>
                                                <Html content={strategy.description || ''} />
                                            </span>
                                        </div>
                                    </div>
                                    <div>{renderSurveyInfo(strategy)}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    if (loading) {
        return <Loader loading />;
    }

    return (
        <div>
            <h1>My Treatment Strategies</h1>
            <div>{renderResourceCenter()}</div>
        </div>
    );
};

export default StudentTreatmentStrategies;
