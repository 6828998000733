import React, { useState, useEffect, useRef } from 'react';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import moment from 'moment';
import Table from 'cccisd-graphql-table';
import Tooltip from 'cccisd-tooltip';

import studentsQuery from './getStudents.graphql';

import RecordProgressExample from './1_Goal_Progress_Modal.png';
import ViewProgressExample from './2_View_Goal_Progress_Modal.png';

import IconPaperplane from 'cccisd-icons/paperplane';
import IconEye from 'cccisd-icons/eye';
import IconEdit from 'cccisd-icons/pencil7';
import IconCog from 'cccisd-icons/cog';
import IconInfo from 'cccisd-icons/info2';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

export const colors = {
    green: '#2c817c',
    blue: '#154988',
    yellow: '#fcbd2b',
    red: '#9a1d22',
    lightBlue: '#eaeef7',
};

const TreatmentAdminister = props => {
    const [loading, setLoading] = useState(true);

    const tableRef = useRef(null);

    useEffect(() => {
        setLoading(false);
    }, []);

    const getStrategyCount = plan => {
        let strategyCount = 0;
        plan.forEach(session => {
            strategyCount += session.settings.resourceIds.length;
        });

        return strategyCount;
    };

    const applyTimezoneOffset = date => {
        // Our server uses UTC time so we need to offset the start/end date entered by client
        return moment(date).utc().format('MM-DD-YYYY');
    };

    const getMockup = mockup => {
        let mockupImage;

        switch (mockup) {
            case 'Record':
                mockupImage = RecordProgressExample;
                break;
            case 'View':
                mockupImage = ViewProgressExample;
                break;
            default:
                // Handle the default case or provide a default image
                mockupImage = ViewProgressExample;
        }

        return mockupImage;
    };

    if (loading) {
        return <Loader loading />;
    }

    return (
        <div>
            <>
                <div className={style.treatmentHeader}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor="treatmentPlan">Active Treatment Plans</label>
                        <select name="treatmentPlan" id="treatmentPlan" value={1}>
                            {/* {assessmentPlanList.map(ap => ( */}
                            <option key={1} value={1}>
                                September Plan
                            </option>
                            {/* // ))} */}
                        </select>
                    </div>
                    <div>
                        <header className={style.summaryHeading}>
                            <h3>September Plan</h3>
                        </header>
                    </div>
                </div>
            </>

            <div className={style.tableActions}>
                <button type="button" className="btn btn-sm btn-warning">
                    <IconPaperplane /> Bulk Remind
                </button>
                <button type="button" className="btn btn-sm btn-secondary">
                    <IconCog /> Settings
                </button>
            </div>
            <div>
                <div className={style.studentsTableWrapper}>
                    <Table
                        ref={tableRef}
                        query={studentsQuery}
                        graphqlVariables={{ instructorPawnId: Fortress.user.acting.id }}
                        renders={{
                            nameAndEmail: ({ value, row }) => {
                                if (!value) {
                                    return null;
                                }
                                return (
                                    <>
                                        <p>{row['user.fullName']}</p>
                                        <p>{row['user.username']}</p>
                                    </>
                                );
                            },

                            goalProgress: ({ value, row }) => {
                                return (
                                    <div className={style.subCell} key={row['pawn.pawnId']}>
                                        <div className={style.actions}>
                                            <Modal
                                                trigger={
                                                    <button type="button" className="btn btn-sm btn-secondary">
                                                        <IconEdit /> Record
                                                    </button>
                                                }
                                                title="View Progress"
                                                size="large"
                                                contentStyle={{ width: '1000px' }}
                                                headerStyle={{ backgroundColor: '#d68f28', color: '#fff' }}
                                                bodyStyle={{ backgroundColor: '#edf2f7' }}
                                            >
                                                <div>
                                                    <div className={style.infoText}>
                                                        <IconInfo />{' '}
                                                        <i>
                                                            Please note that this Record Goal Progress is a mockup. Full
                                                            functionality will be available at a later stage of the
                                                            project.
                                                        </i>
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={getMockup('Record')}
                                                            style={{ maxWidth: '100%' }}
                                                            alt="Example of Record Goal Progress"
                                                        />
                                                    </div>
                                                </div>
                                            </Modal>

                                            <Modal
                                                trigger={
                                                    <button type="button" className="btn btn-sm btn-primary">
                                                        <IconEye /> View
                                                    </button>
                                                }
                                                title="View Progress"
                                                size="large"
                                                contentStyle={{ width: '1000px' }}
                                                headerStyle={{ backgroundColor: '#d68f28', color: '#fff' }}
                                                bodyStyle={{ backgroundColor: '#edf2f7' }}
                                            >
                                                <div>
                                                    <div className={style.infoText}>
                                                        <IconInfo />{' '}
                                                        <i>
                                                            Please note that this View Goal Progress is a mockup. Full
                                                            functionality will be available at a later stage of the
                                                            project.
                                                        </i>
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={getMockup('View')}
                                                            style={{ maxWidth: '100%' }}
                                                            alt="Example of View Goal Progress"
                                                        />
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>
                                    </div>
                                );
                            },

                            assignedOn: ({ value, row }) => {
                                return (
                                    <div className={style.subCell}>
                                        {applyTimezoneOffset(
                                            row['parentRoles.instructor.selectedAssignmentPlan.createdDate']
                                        )}
                                    </div>
                                );
                            },

                            strategiesProgress: ({ value, row }) => {
                                const strategyCount = getStrategyCount(
                                    row['parentRoles.instructor.selectedAssignmentPlan.sessionList']
                                );
                                return (
                                    <div className={style.subCell}>
                                        {strategyCount > 0 ? (
                                            <a
                                                className={style.planLabel}
                                                style={{ textDecoration: 'underline' }}
                                                href={`${Boilerplate.url('myStrategies')}`}
                                            >
                                                2/{strategyCount}
                                            </a>
                                        ) : (
                                            strategyCount
                                        )}
                                    </div>
                                );
                            },

                            completed: ({ value, row, loadData }) => {
                                return (
                                    <div className={style.subCell} key={row['pawn.pawnId']}>
                                        <div className={style.actions}>
                                            <Tooltip title="Send reminder to student.">
                                                <button type="button" className="btn btn-sm btn-warning">
                                                    <IconPaperplane /> Remind
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                );
                            },
                        }}
                        columns={[
                            {
                                name: `fields.studentId`,
                                label: 'Student ID',
                                filter: true,
                                sort: true,
                                className: style.cellCentered,
                                width: '100px',
                            },
                            {
                                name: `user.fullName`,
                                label: 'Name & Email',
                                filter: true,
                                sort: true,
                                className: style.cellCentered,
                                render: 'nameAndEmail',
                            },
                            {
                                name: 'goalProgress',
                                label: 'Goal Progress',
                                render: 'goalProgress',
                                hideInCsv: true,
                            },
                            {
                                name: 'assignedOn',
                                label: 'Assigned On',
                                render: 'assignedOn',
                                filter: true,
                                sort: true,
                                hideInCsv: true,
                            },
                            {
                                name: 'activeStrategies',
                                label: 'Strategies Progress',
                                render: 'strategiesProgress',
                                filter: true,
                                sort: true,
                                hideInCsv: true,
                            },
                            {
                                name: 'completed',
                                label: 'Completed',
                                render: 'completed',
                                hideInCsv: true,
                            },
                        ]}
                        perPage={25}
                        isSimpleCheckboxes
                        hideShowingRowsSummary
                    />
                </div>
            </div>
        </div>
    );
};

export default TreatmentAdminister;
