import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'cccisd-axios';
import { client } from 'cccisd-apollo';
import Table from 'cccisd-graphql-table';
import Modal from 'cccisd-modal';
import Loader from 'cccisd-loader';
import Tooltip from 'cccisd-tooltip';
import { Link } from 'cccisd-react-router';
import { ResourceCenterWithCollection } from 'cccisd-laravel-resources/dist/Collection';
// import ActionButton from '../ActionButton';
import HelpModal from '../HelpModal';
import CreateTreatmentPlan from '../CreateTreatmentPlan';
import AssistLogo from '../../../images/Assist_MH_Logo.svg';
// import { Formik, Form, Field, CccisdInput, CccisdDatepicker, CccisdFieldWrapper } from 'cccisd-formik';
// import StudentContacts from '../StudentContacts';
// import AdministrationTool from '../AdministrationTool';
// import ExportedData from '../../pages/ExportedData';
import _uniq from 'lodash/uniq';
import _pick from 'lodash/pick';

import studentsQuery from './getStudents.graphql';
import getPlanQuery from './getPlan.graphql';
import getResourcesQuery from './getResources.graphql';
// import getPawnsQuery from './getPawns.graphql';

import IconQuill from 'cccisd-icons/quill3';
import IconPlus from 'cccisd-icons/plus2';
import IconUserCheck from 'cccisd-icons/user-check';
import IconClipboard from 'cccisd-icons/clipboard5';
import IconCog from 'cccisd-icons/cog';
import IconEdit from 'cccisd-icons/pencil7';
import IconExit from 'cccisd-icons/exit3';
import style from './style.css';

const Fortress = window.cccisd.fortress;
// const userGroup = Fortress.user.acting.group;
const AppDefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;

export const colors = {
    green: '#2c817c',
    blue: '#154988',
    yellow: '#fcbd2b',
    red: '#9a1d22',
};

const StudentTreatmentDashboard = props => {
    const [loading, setLoading] = useState(true);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedTableRows, setSelectedTableRows] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [planResources, setPlanResources] = useState([]);
    // const [editType, setEditType] = useState('add');

    const helpModalRef = useRef(null);
    const creationModalRef = useRef(null);
    const treatmentModalRef = useRef(null);
    const editModalRef = useRef(null);
    const tableRef = useRef(null);

    let locationHash = window.location.hash;

    const handle = props.assessmentLibraryHandle || 'default';

    let config;
    if (AppDefs.resourceCenters && AppDefs.resourceCenters.list) {
        config = AppDefs.resourceCenters.list.find(rc => rc.id === handle);
    }

    useEffect(() => {
        if (locationHash === '#help') {
            helpModalRef.current.open();
        }
    }, [locationHash]);

    useEffect(() => {
        setLoading(false);
    }, []);

    const formatTableData = useCallback(data => {
        let formattedData = data.map((student, index) => {
            return {
                id: student,
            };
        });
        return formattedData;
    }, []);

    const iconStyle = (bgColor, color) => ({
        backgroundColor: bgColor,
        color: color || '#fff',
        fontSize: '1em',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px ',
    });

    // const getPawnsInfo = async respondentIds => {
    //     let pawnsInfo = [];
    //     try {
    //         const resp = await client.query({
    //             query: getPawnsQuery,
    //             variables: {
    //                 pawnIds: respondentIds,
    //             },
    //             fetchPolicy: 'network-only',
    //         });

    //         pawnsInfo = resp.data.roles.anyRoleList;
    //     } catch (e) {
    //         throw e;
    //     }

    //     return pawnsInfo;
    // };

    const onSubmitEdit = async vals => {
        const requests = vals.respondents
            .map(r => r.id)
            .map(respondentId => {
                return axios.put(
                    Boilerplate.route('api.assignment.plan.update', {
                        plan: selectedPlan.assignmentPlanId,
                    }),
                    {
                        creatingPawnId: Fortress.user.acting.id,
                        label: vals.planName,
                        description: vals.planDescription,
                        // sessionType: 'offset',
                        pawnIds: respondentId,

                        sessions: vals.sessions.map(session => {
                            // console.log({
                            //     sessionId: session.sessionId,
                            //     label: session.label,
                            //     deploymentId: session.deploymentId,
                            //     sessionType: 'offset',
                            //     offset: 0,
                            //     opensAt: null,
                            //     closesAt: null,
                            //     sessionDeploymentInfo: session.sessionDeploymentInfo,
                            //     settings: filterSessionSettings(session.settings),
                            // });
                            return {
                                sessionId: session.sessionId,
                                label: session.label,
                                deploymentId: session.deploymentId,
                                sessionType: 'offset',
                                offset: 0,
                                opensAt: null,
                                closesAt: null,
                                sessionDeploymentInfo: session.sessionDeploymentInfo,
                                settings: filterSessionSettings(session.settings),
                            };
                        }),
                    }
                );
            });
        return axios.all(requests).then(responses => {
            editModalRef.current.close();
            tableRef.current.loadData();
        });
    };

    const getPlan = async (pawnId, planId) => {
        let resp;
        try {
            resp = await client.query({
                query: getPlanQuery,
                variables: {
                    pawnId,
                    planId,
                },
                fetchPolicy: 'network-only',
            });

            setSelectedPlan(resp.data.roles.instructor.selectedAssignmentPlan);
        } catch (e) {
            throw e;
        }

        return resp;
    };

    const getFlowLanguages = metadata => {
        let languages = new Set();
        if (metadata.surveyInfo && metadata.surveyInfo.flowList) {
            metadata.surveyInfo.flowList.forEach(flow => {
                languages.add(flow.language);
            });
        }
        return [...languages];
    };

    const formatResourcesForCollection = resourceList => {
        const formattedResources = resourceList.map(r => {
            const tags = r.tagList.reduce((acc, cur) => ({ ...acc, [cur.tagId]: cur.handle }), {});
            return {
                id: r.resourceId,
                label: r.label,
                description: r.description,
                tags,
                languages: getFlowLanguages(JSON.parse(r.metadata)),
                resource: { published_metadata: r.metadata },
            };
        });

        return formattedResources;
    };

    const editAssessmentPlan = async (pawnId, planId, type) => {
        setLoading(true);
        await getPlan(pawnId, planId).then(async resp => {
            const resourceIds = _uniq(
                resp.data.roles.instructor.selectedAssignmentPlan.sessionList
                    .map(session => session.settings.resourceIds)
                    .flat()
            );
            let resourcesResp;
            try {
                if (resourceIds && resourceIds.length > 0) {
                    resourcesResp = await client.query({
                        query: getResourcesQuery,
                        variables: {
                            resourceIds,
                        },
                        fetchPolicy: 'network-only',
                    });
                    setPlanResources(resourcesResp.data.resources.resourceList);
                }
            } catch (e) {
                throw e;
            }
        });
        setLoading(false);
        setSelectedStudents([{ id: pawnId }]);
        // setEditType(type);
        editModalRef.current.open();
    };

    const filterSessionSettings = settings => {
        const filteredSettings = {
            ...settings,
            sessionCollection: settings.sessionCollection.map(sc => {
                return {
                    ...sc,
                    resource: _pick(sc.resource, [
                        'id',
                        'handle',
                        'published_label',
                        'published_description',
                        'published_metadata',
                    ]),
                };
            }),
        };
        return filteredSettings;
    };

    const renderResourceTiles = (data, renderDefault) => {
        return <div className={style.resourceTiles}>{renderDefault(data)}</div>;
    };

    const AdministerPage = async () => {
        window.location.href = Boilerplate.url('/treatmentAdminister');
    };

    const getStrategyCount = plan => {
        let strategyCount = 0;
        plan.forEach(session => {
            strategyCount += session.settings.resourceIds.length;
        });

        return strategyCount;
    };

    if (loading) {
        return <Loader loading />;
    }

    return (
        <div>
            <Modal
                ref={treatmentModalRef}
                trigger={null}
                size="82vw"
                contentStyle={{ width: '1300px' }}
                headerStyle={{ backgroundColor: '#2C817C', color: '#fff' }}
                bodyStyle={{ backgroundColor: '#edf2f7' }}
                beforeClose={() => {
                    window.location.hash = '';
                    return null;
                }}
                title="Create Treatment Plan"
                render={({ closeModal }) => <CreateTreatmentPlan closeModal={closeModal} config={config} />}
            />

            <Modal trigger={null} size="99vw" ref={editModalRef}>
                <ResourceCenterWithCollection
                    handle="TreatmentLibrary"
                    collectionUserList={selectedStudents}
                    collectionBuckets={['Student-led', 'Provider-led']}
                    onSubmit={onSubmitEdit}
                    editCollection="copy"
                    selectedPlan={selectedPlan}
                    planResources={formatResourcesForCollection(planResources)}
                    disableTimepoints
                    collectionName="Treatment Plan"
                    planName={props.planName}
                    assessmentLibraryLabel="Treatment Stategies Library"
                    logo={AssistLogo}
                    renderResourceTiles={props.renderResourceTiles || renderResourceTiles}
                    collectionBucketsFormTitle={props.collectionBucketsFormTitle}
                    styleConfig={config.displayOptions}
                    baselineSessionLabel={props.baselineSessionLabel}
                />
            </Modal>
            <Modal
                trigger={null}
                size="large"
                ref={helpModalRef}
                beforeClose={() => {
                    window.location.hash = '';
                    return null;
                }}
                title="How to create a Treatment Plan"
                render={({ closeModal }) => <HelpModal closeModal={closeModal} />}
            />

            <>
                {props.header}
                <div className={style.tableActions}>
                    <div style={{ display: 'flex', gap: '1em' }}>
                        {selectedTableRows.length > 0 ? (
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{ fontWeight: 'bold' }}
                                    onClick={() => {
                                        setSelectedStudents(formatTableData(selectedTableRows));
                                        treatmentModalRef.current.open();
                                    }}
                                >
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ fontSize: '2em' }}>
                                            <IconQuill />
                                        </div>
                                        <div style={{ textAlign: 'left', marginLeft: '0.5em' }}>
                                            Create <br />
                                            Treatment Plan
                                        </div>
                                    </div>
                                </button>
                            </div>
                        ) : (
                            <Tooltip title="Select students to create a Treatment Plan">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{ fontWeight: 'bold' }}
                                    disabled
                                >
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ fontSize: '2em' }}>
                                            <IconQuill />
                                        </div>
                                        <div style={{ textAlign: 'left', marginLeft: '0.5em' }}>
                                            Create <br />
                                            Treatment Plan
                                        </div>
                                    </div>
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </>

            <div>
                <div className={style.studentsTableWrapper}>
                    <Table
                        ref={tableRef}
                        query={studentsQuery}
                        graphqlVariables={{ instructorPawnId: Fortress.user.acting.id }}
                        selectedRows={selectedTableRows}
                        rowSelectedCallback={selectedRows => {
                            setSelectedTableRows(selectedRows);
                        }}
                        renders={{
                            nameAndEmail: ({ value, row }) => {
                                if (!value && !row['user.username']) {
                                    return null;
                                }
                                return (
                                    <>
                                        <p>{row['user.fullName']}</p>
                                        <p>{row['user.username']}</p>
                                    </>
                                );
                            },
                            treatmentPlans: ({ value, row }) => {
                                return (
                                    <div className={style.subCell} key={row['pawn.pawnId']}>
                                        <div className={style.planLabel}>
                                            {row['parentRoles.instructor.selectedAssignmentPlan.label']}
                                        </div>
                                    </div>
                                );
                            },
                            activeStrategies: ({ value, row }) => {
                                const strategyCount = getStrategyCount(
                                    row['parentRoles.instructor.selectedAssignmentPlan.sessionList']
                                );
                                return (
                                    <div className={style.subCell}>
                                        {strategyCount > 0 ? (
                                            <a
                                                className={style.planLabel}
                                                style={{ textDecoration: 'underline' }}
                                                href={`${Boilerplate.url('strategies')}`}
                                            >
                                                {strategyCount}
                                            </a>
                                        ) : (
                                            strategyCount
                                        )}
                                    </div>
                                );
                            },

                            actions: ({ value, row, loadData }) => {
                                return (
                                    <div className={style.subCell} key={row['pawn.pawnId']}>
                                        <div className={style.actions}>
                                            <Tooltip title="Assign Treatment Strategies to this treatment plan.">
                                                <button
                                                    type="button"
                                                    className={`btn btn-xs ${style.button}`}
                                                    style={iconStyle('#C9A100')}
                                                    onClick={() =>
                                                        editAssessmentPlan(
                                                            Fortress.user.acting.id,
                                                            row[
                                                                'parentRoles.instructor.selectedAssignmentPlan.assignmentPlanId'
                                                            ],
                                                            'modify'
                                                        )
                                                    }
                                                    disabled={
                                                        row[
                                                            'parentRoles.instructor.selectedAssignmentPlan.assignmentPlanId'
                                                        ]
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    <IconUserCheck />
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Manage Treatment Strategies for this  treatment plan.">
                                                <button
                                                    type="button"
                                                    className={`btn btn-xs ${style.button}`}
                                                    style={iconStyle('#5B7DCB')}
                                                    // onClick={() =>
                                                    //     editAssessmentPlan(
                                                    //         Fortress.user.acting.id,
                                                    //         row[
                                                    //             'parentRoles.instructor.selectedAssignmentPlan.assignmentPlanId'
                                                    //         ],
                                                    //         'modify'
                                                    //     )
                                                    // }
                                                    onClick={() => {
                                                        setSelectedStudents(formatTableData(selectedTableRows));
                                                        treatmentModalRef.current.open();
                                                    }}
                                                >
                                                    <IconClipboard />
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Go to Administer page">
                                                <button
                                                    type="button"
                                                    className={`btn btn-xs ${style.button}`}
                                                    style={iconStyle(colors.blue)}
                                                    onClick={() => AdministerPage()}
                                                >
                                                    <IconCog />
                                                </button>
                                            </Tooltip>
                                            <button
                                                type="button"
                                                className={`btn btn-xs ${style.button}`}
                                                style={iconStyle(colors.green)}
                                                // onClick={() => editStudent(row)}
                                            >
                                                <IconEdit />
                                            </button>
                                            <Tooltip title="Discharge student">
                                                <button
                                                    type="button"
                                                    className={`btn btn-xs ${style.button}`}
                                                    style={iconStyle('#A34139')}
                                                    // onClick={() => dischargeStudent(row)}
                                                >
                                                    <IconExit />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                );
                            },
                        }}
                        rowActions={[
                            {
                                name: 'createPlan',
                                title: 'Create Treatment Plan',
                                action: async ({ selectedRows, data, loadData }) => {
                                    setSelectedStudents(formatTableData(selectedRows));
                                    creationModalRef.current.open();
                                },
                            },
                        ]}
                        columns={[
                            {
                                name: `fields.studentId`,
                                label: 'Student ID',
                                filter: true,
                                sort: true,
                                className: style.cellCentered,
                                width: '100px',
                            },
                            {
                                name: `user.fullName`,
                                label: 'Name & Email',
                                filter: true,
                                sort: true,
                                className: style.cellCentered,
                                render: 'nameAndEmail',
                            },
                            {
                                name: `groupName`,
                                label: 'School',
                                filter: true,
                                sort: true,
                                className: style.cellCentered,
                            },
                            {
                                name: 'treatmentPlans',
                                label: 'Treatment Plans',
                                render: 'treatmentPlans',
                                hideInCsv: true,
                            },
                            {
                                name: 'activeStrategies',
                                label: 'Active Strategies',
                                render: 'activeStrategies',
                                hideInCsv: true,
                            },
                            {
                                name: 'actions',
                                label: 'Actions',
                                render: 'actions',
                                hideInCsv: true,
                            },
                        ]}
                        perPage={25}
                        isSimpleCheckboxes
                        hideShowingRowsSummary
                    />
                </div>
                <Link to="/manage" className="btn btn-primary">
                    <>
                        <IconPlus spaceRight />
                        Add Students
                    </>
                </Link>
            </div>
        </div>
    );
};

export default StudentTreatmentDashboard;
